<template>
	<form
		@submit.prevent="signIn"
		@keydown="clearError($event.target.name);"
		@keyup="attemptSubmit();">
		<p>{{ translate('enter_tfa') }}</p>
		<b-input-group
			id="code"
			class="mb-3">
			<b-input-group-prepend>
				<b-input-group-text>
					<i class="icon-key" />
				</b-input-group-text>
			</b-input-group-prepend>
			<b-form-input
				v-model.trim="code"
				:placeholder="translate('tfa_code')"
				:class="hasError('code') ? 'is-invalid' : ''"
				type="text"
				name="code"
				class="form-control" />
			<template v-if="hasError('code')">
				<span
					v-for="error in errors['code']"
					:key="error"
					class="invalid-feedback animated fadeIn"
					v-text="error" />
			</template>
		</b-input-group>
		<div
			v-if="errorMessage"
			class="alert alert-danger mb-3"
			role="alert"
			v-text="errorMessage" />
		<b-row>
			<b-col cols="12">
				<b-button
					:disabled="Object.keys(errors).length > 0 || code.length !== 6"
					variant="primary"
					class="px-4 py-2 btn-block"
					type="submit">
					{{ translate('authenticate') }}
				</b-button>
			</b-col>
		</b-row>
	</form>
</template>
<script>
import { Auth } from '@/translations';

export default {
	name: 'LoginForm',
	messages: Auth,
	props: {
		errorsImport: {
			type: Object,
			default() {
				return {};
			},
		},
		errorMessage: {
			type: String,
			default: null,
		},
		username: {
			type: String,
			default: '',
		},
		password: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			code: '',
			errors: this.errorsImport,
		};
	},
	watch: {
		errorsImport(value) {
			this.errors = value;
		},
	},
	methods: {
		signIn() {
			this.$emit('signIn', {
				username: this.username,
				password: this.password,
				tfaCode: this.code,
			});
		},
		hasError(field) {
			if (typeof this.errors[field] !== 'undefined') { return true; }
			return false;
		},
		attemptSubmit() {
			if (this.code.length === 6) {
				this.signIn();
			}
		},
		clearError(field) {
			if (field) {
				delete this.errors[field];
				this.errors = { ...this.errors };
			}
		},
	},
};
</script>
