<!-- eslint-disable vue/no-v-html -->
<template>
	<form
		@submit.prevent="signIn"
		@keydown="clearError($event.target.name);">
		<b-input-group
			id="username"
			class="mb-3">
			<b-input-group-prepend><b-input-group-text><i class="icon-user" /></b-input-group-text></b-input-group-prepend>
			<b-form-input
				v-model.trim="credentials.username"
				:placeholder="translate('username')"
				:class="hasError('username') ? 'is-invalid' : ''"
				type="text"
				name="username"
				class="form-control" />
			<template v-if="hasError('username')">
				<span
					v-for="error in errors['username']"
					:key="error"
					class="invalid-feedback animated fadeIn"
					v-text="error" />
			</template>
		</b-input-group>
		<b-input-group
			id="password"
			class="mb-4">
			<b-input-group-prepend><b-input-group-text><i class="icon-lock" /></b-input-group-text></b-input-group-prepend>
			<b-form-input
				v-model.trim="credentials.password"
				:placeholder="translate('password')"
				:class="hasError('password') ? 'is-invalid' : ''"
				:type="showPassword ? 'text' : 'password'"
				name="password"
				class="form-control border-right-0" />
			<b-input-group-append>
				<div class="h-100 input-group-text bg-white pointer">
					<icon-toggle
						toggle-id="password-toggle"
						:tooltip="translate(showPassword ? 'hide' : 'show')"
						:value.sync="showPassword"
						active-icon="fas fa-eye-slash"
						inactive-icon="fas fa-eye"
						hover-class="text-muted" />
				</div>
			</b-input-group-append>
			<template v-if="hasError('password')">
				<span
					v-for="error in errors['password']"
					:key="error"
					class="invalid-feedback animated fadeIn"
					v-text="error" />
			</template>
		</b-input-group>
		<div
			v-if="errorMessage"
			class="alert alert-danger mb-3"
			role="alert"
			v-text="errorMessage" />
		<b-row>
			<b-col cols="12">
				<b-button
					:disabled="Object.keys(errors).length > 0"
					variant="primary"
					class="px-4 py-2 btn-block"
					type="submit">
					{{ translate('login') }}
				</b-button>
			</b-col>
			<b-col
				:class="windowWidth === 'xs' ? 'text-right col-6' : 'text-left'"
				class="col-12 col-sm-6">
				<b-button
					v-b-modal="'spam-policy-modal'"
					variant="link"
					style="color: #b14a1a;"
					class="px-0 text-decoration-none">
					{{ translate('anti_spam_policy') }}
				</b-button>
			</b-col>
			<b-col
				v-if="showForgotPassword"
				class="col-12 col-sm-6 text-right">
				<b-button
					variant="link"
					class="px-0"
					style="color: #b14a1a;"
					@click="$router.push({ name: 'ForgotPassword' })">
					{{ translate('forgot_password_button') }}
				</b-button>
			</b-col>
		</b-row>
		<b-modal
			id="spam-policy-modal"
			ok-only>
			<template v-slot:modal-title>
				{{ translate('anti_spam_policy') }}
			</template>
			<div v-html="translate('anti_spam_policy_text', { c1: company, c2: company, c3: company, c4: company, support:companyEmail, support1:companyEmail })" />
		</b-modal>
	</form>
</template>
<script>
import IconToggle from '@/components/IconToggle';
import { Auth } from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'LoginForm',
	messages: Auth,
	components: { IconToggle },
	mixins: [WindowSizes],
	props: {
		errorsImport: {
			type: Object,
			default() {
				return {};
			},
		},
		showForgotPassword: {
			type: Boolean,
			default: false,
		},
		errorMessage: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			company: process.env.VUE_APP_TITLE,
			companyEmail: process.env.VUE_APP_COMPANY_EMAIL,
			credentials: {
				username: '',
				password: '',
			},
			errors: this.errorsImport,
			showPassword: false,
		};
	},
	watch: {
		errorsImport(value) {
			this.errors = value;
		},
	},
	methods: {
		signIn() {
			this.$emit('signIn', this.credentials);
		},
		hasError(field) {
			if (typeof this.errors[field] !== 'undefined') { return true; }
			return false;
		},
		clearError(field) {
			if (field) {
				delete this.errors[field];
				this.errors = { ...this.errors };
			}
		},
	},
};
</script>
