<template>
	<div>
		<div class="row">
			<div class="col">
				<b-card
					class="mb-0 bg-transparent border-0"
					no-body>
					<div class="card-body p-0">
						<login-form
							v-if="!showTfa"
							:errors-import="form.errors.all()"
							:error-message="form.errors.message() || customErrorMessage"
							:show-forgot-password="true"
							@signIn="login" />
						<tfa-form
							v-else
							:username="form.username"
							:password="form.password"
							:errors-import="form.errors.all()"
							:error-message="form.errors.message() || customErrorMessage"
							@signIn="login" />
					</div>
				</b-card>
			</div>
		</div>
		<!-- <div class="row mt-3">
			<div class="col">
				<b-card class="mb-0 loginCard p-0">
					<div class="row">
						<div class="col">
							<img
								:src="onboardingImage"
								class="rounded mx-auto d-block">
						</div>
					</div>
					<div class="row h-100">
						<div class="col my-auto text-center">
							<h4 class="mb-3 text-white">{{ translate('previous_account') }}</h4>
							<b-button
								variant="primary"
								@click="$router.push({ name: 'OnboardingProcess' })">
								{{ translate('start_onboarding') }}
							</b-button>
						</div>
					</div>
				</b-card>
			</div>
		</div> -->
	</div>
</template>

<script>

import { Auth } from '@/translations';
import { DEFAULT_COUNTRY as defaultCountry } from '@/settings/Country';
import { admin } from '@/settings/Roles';
import DocumentTitle from '@/mixins/DocumentTitle';
import Common from '@/mixins/Common';
import Captcha from '@/mixins/Captcha';
import DefinePermissions from '@/util/DefinePermissions';
import LoginForm from './components/Login/LoginForm';
import TfaForm from './components/Login/TfaForm';

export default {
	name: 'Login',
	messages: Auth,
	components: {
		LoginForm,
		TfaForm,
	},
	mixins: [DocumentTitle, Captcha, Common],
	data() {
		return {
			form: new this.$Form({}),
			tfaIsEnabled: null,
			customErrorMessage: null,
			admin,
		};
	},
	computed: {
		onboardingImage() {
			return require('@/assets/images/common/onboarding.png'); // eslint-disable-line global-require
		},
		showTfa() {
			return this.tfaIsEnabled === true;
		},
	},
	mounted() {
		this.setFocus('username');
	},
	methods: {
		login(payload) {
			const { username, password, tfaCode } = payload;
			this.getCaptchaToken('login').then((response) => {
				this.$user.login(username, password, tfaCode, response).then((loginResponse) => {
					if (loginResponse.tfa_enabled) {
						this.tfaIsEnabled = loginResponse.tfa_enabled;
						this.form = new this.$Form({ username, password });
						this.setFocus('code');
					} else {
						this.setRegisterPositionValues({});
						try {
							this.country = this.$user.details().country.iso_code_2;
							this.setStoredCountry(this.$user.details().country.iso_code_2);
							this.registerCountry = this.$user.details().country.iso_code_2;
							this.setRegisterCountry(this.$user.details().country.iso_code_2);
						} catch (error) {
							this.country = defaultCountry;
							this.setStoredCountry(defaultCountry);
							this.registerCountry = defaultCountry;
							this.setRegisterCountry(defaultCountry);
						}
						this.$forceUpdate();
						const { rules } = DefinePermissions(this.$user.details().permissions);
						this.$ability.update(rules);
						if (this.siftEnabled() && !this.admin.includes(this.$user.details().type)) {
							const userId = this.$user.details().id;
							// eslint-disable-next-line
							_sift.push(['_setUserId', userId]);
						}
						this.$router.push({ name: 'MainHome' });
					}
				}).catch((errors) => {
					this.form = new this.$Form(payload);
					this.form.errors.record(errors.response);
					if (typeof this.form.errors.errors !== 'undefined' && typeof this.form.errors.errors['g-recaptcha-response'] !== 'undefined') {
						this.customErrorMessage = this.translate('recaptcha_went_wrong');
						this.form.errors.clear('g-recaptcha-response');
					}
				});
			});
		},
	},
};
</script>

<style>
	.card.loginCard {
		background: rgba(0,0,0,0.6);
		border: 0;
	}

</style>
